export enum BLOCK_TYPE {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  SLIDE = 'SLIDE'
}

export enum PREVIEW_DEVICE {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}
