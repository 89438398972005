<div class="py-8">
  <div
    class="mx-auto bg-white"
    [ngClass]="isDesktop ? 'max-w-[1440px]' : 'max-w-[390px]'"
  >
    <app-soctrip-header [previewDevice]="previewDevice"></app-soctrip-header>

    <body

    >
      <div *ngFor="let layer of data" [style.backgroundColor]="layer.bgColor">
        <div
          class="flex flex-col mx-auto"
          [ngClass]="isDesktop ? 'py-6 gap-4 max-w-[960px]' : 'p-2 gap-2 max-w-[390px]'"
        >
          <h1
            *ngIf="layer.name"
            class="text-xl font-semibold"
            [style.color]="layer.textColor"
          >
            {{ layer.name }}
          </h1>

          <p-editor
            *ngIf="layer.description"
            styleClass="editor-preview"
            [style.backgroundColor]="layer.bgColor"
            [style.color]="layer.textColor"
            [readonly]="true"
            [ngModel]="layer.description"
          ></p-editor>

          <div class="flex" [ngClass]="isDesktop ? 'gap-4' : 'gap-2'">
            <div *ngFor="let block of layer.blocks" [style.flex]="block.flex">
              <div [ngSwitch]="block.type">
                <!-- TEXT -->
                <div *ngSwitchCase="BLOCK_TYPE.TEXT">
                  <p-editor
                    styleClass="editor-preview"
                    [readonly]="true"
                    [ngModel]="block.data?.text"
                  ></p-editor>
                </div>

                <!-- IMAGE -->
                <div class="text-sm" *ngSwitchCase="BLOCK_TYPE.IMAGE">
                  <app-custom-image
                    [imgId]="
                      isDesktop
                        ? block.data?.desktopImg?.id
                        : block.data?.mobileImg?.id
                    "
                    imageClass="w-[unset]"
                  ></app-custom-image>
                </div>

                <div>
                  <p-galleria
                    *ngIf="block.data?.banners?.length > 0"
                    [circular]="true"
                    [transitionInterval]="2000"
                    [value]="block.data.banners"
                    [showItemNavigators]="true"
                    [showThumbnails]="false"
                    [showIndicators]="true"
                    [autoPlay]="true"
                    [indicatorsPosition]="'bottom'"
                  >
                    <ng-template pTemplate="itempreviousicon">
                      <div
                        class="bg-black/40 h-11 w-11 flex items-center justify-center rounded-full cursor-pointer hover:bg-black/60"
                      >
                        <i
                          class="{{
                            SoctripIcons.CHEVRON_LEFT
                          }} text-white text-2xl"
                        ></i>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="itemnexticon">
                      <div
                        class="bg-black/40 h-11 w-11 flex items-center justify-center rounded-full cursor-pointer hover:bg-black/60"
                      >
                        <i
                          class="{{
                            SoctripIcons.CHEVRON_RIGHT
                          }} text-white text-2xl"
                        ></i>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="item" let-item>
                      <app-custom-image
                        class="w-full h-[240px] object-cover"
                        [imgId]="
                          isDesktop ? item.desktopImg?.id : item.mobileImg?.id
                        "
                      />
                    </ng-template>
                  </p-galleria>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
</div>
