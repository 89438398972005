import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  FAKE_TOKEN =
    'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJjY2N1b25nQHRtYS5jb20udm4iLCJpYXQiOjE3MjczMjExMjgsInVzZXJfaWQiOiIyZjMxMDI5ZC02NTJiLTRjNDQtYmIyOS00OGVlNTlhOGI5OGEiLCJmaXJzdG5hbWUiOiJDdW9uZyIsImxhc3RuYW1lIjoiQ2FvIiwiYXZhdGFyIjoidm5fMjAyNDA5MjVUMTIyODIzNjMyODAxNTA2XzJmMzEwMjlkLTY1MmItNGM0NC1iYjI5LTQ4ZWU1OWE4Yjk4YV8xNzI3MjQyMTAzNjYwIiwiY2l0eV9jb2RlIjoiNzAwMDAwIiwicm9sZSI6IiIsImxhbmd1YWdlIjoiRU4iLCJjb3VudHJ5X2NvZGUiOiJWTiIsInBsYXRmb3JtIjoiVyIsImV4cCI6MTcyNzMyMjkyOH0.w1EBkDluKP-dyEhG10Mr96EiCgCgXu0YYFNfX-l5H4CtjzL69j0YMcVKhwvly3jrT-DMeiLKyktw4dUlD_wFTw';
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('accessToken') || this.FAKE_TOKEN}`,
    );
    const authReq = request.clone({ headers });
    return next.handle(authReq).pipe(
      map((event) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == HttpStatusCode.Unauthorized) {
        }
        return throwError(() => error);
      }),
    );
  }
}
