import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { PREVIEW_DEVICE } from 'src/app/core/enum/marketing-builder';
import { Attachment } from 'src/app/core/models/interfaces/attachment';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent {
  @Input() desktopImage: Attachment;
  @Input() mobileImage: Attachment;
  @Input() isLoading: boolean = false;

  @Output() uploadImage = new EventEmitter<{
    data: Event;
    device: PREVIEW_DEVICE;
  }>();
  @Output() deleteImage = new EventEmitter<PREVIEW_DEVICE>();

  readonly SoctripIcons = SoctripIcons;
  readonly DEVICES = [
    {
      name: 'Desktop',
      value: PREVIEW_DEVICE.DESKTOP,
    },
    {
      name: 'Mobile',
      value: PREVIEW_DEVICE.MOBILE,
    },
  ];
  device: PREVIEW_DEVICE = PREVIEW_DEVICE.DESKTOP;

  constructor() {}

  get image() {
    return this.device === PREVIEW_DEVICE.DESKTOP
      ? this.desktopImage
      : this.mobileImage;
  }

  onSelectImage(event: Event) {
    this.uploadImage.emit({
      data: event,
      device: this.device,
    });
  }
}
