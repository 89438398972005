import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ElementSelected,
  ImageBlockData,
  Layer,
} from 'src/app/core/models/interfaces/marketing-builder';
import {
  BLOCKS_BUILDER,
  IMAGE_ALIGNS,
} from 'src/app/core/constants/marketing-builder';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { FileService } from 'src/app/core/services/file.service';
import {
  BLOCK_TYPE,
  PREVIEW_DEVICE,
} from 'src/app/core/enum/marketing-builder';

@Component({
  selector: 'app-right-bar-builder',
  templateUrl: './right-bar-builder.component.html',
  styleUrls: ['./right-bar-builder.component.scss'],
})
export class RightBarBuilderComponent {
  @Input() elementSelected: ElementSelected;
  @Input() layers: Layer[] = [];

  @Output() layersChange = new EventEmitter<Layer[]>();

  readonly BLOCK_TYPE = BLOCK_TYPE;
  readonly IMAGE_ALIGNS = IMAGE_ALIGNS;

  constructor(private fileService: FileService) {}

  readonly SoctripIcons = SoctripIcons;

  get blockData() {
    return this.layers[this.elementSelected.layerIndex].blocks[
      this.elementSelected.blockIndex
    ];
  }

  get layerData() {
    return this.layers[this.elementSelected.layerIndex];
  }

  getTitleRightBar(): string {
    if (this.elementSelected.scope === 'LAYER') {
      return 'Layer';
    } else {
      const type = this.blockData.type;
      if (type) {
        return BLOCKS_BUILDER.find((item) => item.value === type)?.name || '';
      } else {
        return '';
      }
    }
  }

  onUploadImage({ data, device }: { data: Event; device: PREVIEW_DEVICE }) {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    if (this.layers[layerIndex].blocks[blockIndex].data) {
      this.layers[layerIndex].blocks[blockIndex].data = {
        ...this.layers[layerIndex].blocks[blockIndex].data,
        isLoading: true,
      };
    }

    const file = (data.target as HTMLInputElement).files?.[0];
    if (file) {
      this.fileService.uploadFile(file).subscribe({
        next: (res) => {
          const attachment = res.data;
          if (device === PREVIEW_DEVICE.DESKTOP) {
            this.layers[layerIndex].blocks[blockIndex].data = {
              ...this.layers[layerIndex].blocks[blockIndex].data,
              isLoading: false,
              desktopImg: attachment,
            } as ImageBlockData;
          } else {
            this.layers[layerIndex].blocks[blockIndex].data = {
              ...this.layers[layerIndex].blocks[blockIndex].data,
              isLoading: false,
              mobileImg: attachment,
            } as ImageBlockData;
          }
        },
      });
    }
  }

  onDeleteImage(event: PREVIEW_DEVICE) {
    const { layerIndex, blockIndex } = this.elementSelected;

    if (event === PREVIEW_DEVICE.DESKTOP) {
      this.layers[layerIndex].blocks[blockIndex].data = {
        ...this.layers[layerIndex].blocks[blockIndex].data,
        desktopImg: undefined,
      };
    } else {
      this.layers[layerIndex].blocks[blockIndex].data = {
        ...this.layers[layerIndex].blocks[blockIndex].data,
        mobileImg: undefined,
      };
    }
  }

  onUploadBanner(
    { data, device }: { data: Event; device: PREVIEW_DEVICE },
    bannerIndex: number,
  ) {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    const file = (data.target as HTMLInputElement).files?.[0];
    if (!file) return;

    if (
      this.layers[layerIndex].blocks[blockIndex].data?.banners?.[bannerIndex]
    ) {
      const banners = [
        ...(this.layers[layerIndex].blocks[blockIndex].data?.banners || []),
      ];
      banners[bannerIndex] = {
        ...banners[bannerIndex],
        isLoading: true,
      };

      this.layers[layerIndex].blocks[blockIndex].data = {
        banners: [...banners],
      };

      this.fileService.uploadFile(file).subscribe({
        next: (res) => {
          const attachment = res.data;
          const banners = [
            ...(this.layers[layerIndex].blocks[blockIndex].data?.banners || []),
          ];

          if (device === PREVIEW_DEVICE.DESKTOP) {
            banners[bannerIndex] = {
              ...banners[bannerIndex],
              desktopImg: attachment,
              isLoading: false,
            };
          } else {
            banners[bannerIndex] = {
              ...banners[bannerIndex],
              mobileImg: attachment,
              isLoading: false,
            };
          }

          this.layers[layerIndex].blocks[blockIndex].data = {
            banners: [...banners],
          };
        },
      });
    }
  }

  onDeleteBanner(event: PREVIEW_DEVICE, bannerIndex: number) {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    if (
      this.layers[layerIndex].blocks[blockIndex].data?.banners?.[bannerIndex]
    ) {
      const banners = [
        ...(this.layers[layerIndex].blocks[blockIndex].data?.banners || []),
      ];

      if (event === PREVIEW_DEVICE.DESKTOP) {
        banners[bannerIndex].desktopImg = undefined;
      } else {
        banners[bannerIndex].mobileImg = undefined;
      }

      this.layers[layerIndex].blocks[blockIndex].data = {
        banners: [...banners],
      };
    }
  }

  onAddBanner() {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    const banners = [
      ...(this.layers[layerIndex].blocks[blockIndex].data?.banners || []),
      {},
    ];

    this.layers[layerIndex].blocks[blockIndex].data = {
      banners: [...banners],
    };
  }

  onChangeAlignImage(value: string) {
    const { layerIndex, blockIndex } = this.elementSelected;
    this.layers[layerIndex].blocks[blockIndex].data = {
      ...this.layers[layerIndex].blocks[blockIndex].data,
      alignment: value,
    };
  }

  onUpdateLayers() {
    this.layers = [...this.layers];
    this.layersChange.emit(this.layers);
  }
}
