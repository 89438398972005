import { BLOCK_TYPE } from '../enum/marketing-builder';
import { SoctripIcons } from './soctrip-icon.enum';

export const BLOCKS_BUILDER = [
  {
    name: 'Text',
    value: BLOCK_TYPE.TEXT,
    icon: SoctripIcons.TYPE_01,
  },
  {
    name: 'Image',
    value: BLOCK_TYPE.IMAGE,
    icon: SoctripIcons.IMAGE_03,
  },
  {
    name: 'Slide',
    value: 'SLIDE',
    icon: SoctripIcons.COLUMNS_02,
  },
  {
    name: 'Video',
    value: 'VIDEO',
    icon: SoctripIcons.VIDEO_RECORDER,
  },
  {
    name: 'Tab',
    value: 'TAB',
    icon: SoctripIcons.PASSCODE,
  },
  {
    name: 'Product list',
    value: 'PRODUCT_LIST',
    icon: SoctripIcons.PACKAGE,
  },
  {
    name: 'Voucher',
    value: 'VOUCHER',
    icon: SoctripIcons.TICKET_01,
  },
];

export const LAYOUTS_BUILDER = [
  [1],
  [1, 1],
  [1, 1, 1],
  [1, 1, 1, 1],
  [1, 1, 1, 1, 1],
  [1, 2],
  [2, 1],
];

export const IMAGE_ALIGNS = [
  {
    value: 'LEFT',
    icon: SoctripIcons.ALIGN_LEFT,
  },
  {
    value: 'CENTER',
    icon: SoctripIcons.ALIGN_CENTER,
  },
  {
    value: 'RIGHT',
    icon: SoctripIcons.ALIGN_RIGHT,
  },
];
