<app-header-builder
  [(mode)]="mode"
  [(previewDevice)]="previewDevice"
></app-header-builder>

<div class="pt-[60px] bg-gray-100">
  <div
    *ngIf="mode === 'BUILDER'; else previewTemplate"
    class="flex min-h-[calc(100vh-112px)]"
  >
    <!-- LEFT -->
    <app-left-bar-builder
      [elementSelected]="elementSelected"
      [layers]="layers"
    ></app-left-bar-builder>

    <!-- CENTER -->
    <div class="w-[calc(100vw-784px)] pb-6 my-8 px-12">
      <div class="bg-white">
        <app-soctrip-header></app-soctrip-header>

        <div
          class="layer-list"
          cdkDropList
          (cdkDropListDropped)="onDropLayer($event)"
        >
          <div
            *ngFor="let layer of layers; let layerIndex = index"
            cdkDrag
            class="layer-item relative border px-10 py-6 bg-white"
            [style.backgroundColor]="layer.bgColor"
            [ngClass]="[
              layerIndex === elementSelected.layerIndex &&
              elementSelected.scope === 'LAYER'
                ? 'border-gray-600'
                : 'border-transparent'
            ]"
            (click)="onClickLayer($event, layerIndex)"
          >
            <div class="flex flex-col gap-4">
              <h1
                *ngIf="layer.name"
                [style.color]="layer.textColor"
                class="text-xl font-semibold"
              >
                {{ layer.name }}
              </h1>

              <p-editor
                *ngIf="layer.description"
                styleClass="editor-preview"
                [style.color]="layer.textColor"
                [style.backgroundColor]="layer.bgColor"
                [readonly]="true"
                [ngModel]="layer.description"
              ></p-editor>

              <div
                class="flex items-center gap-4"
                *ngIf="layer.blocks.length; else dropLayerTemplate"
              >
                <div
                  [style.flex]="block.flex"
                  *ngFor="let block of layer.blocks; let blockIndex = index"
                >
                  <!-- DROP BLOCK -->
                  <div
                    *ngIf="!block.type; else blockTemplate"
                    class="flex flex-col gap-1 items-center justify-center py-6 bg-primary-50 border border-dashed border-primary-600 text-primary-600"
                    dndDropzone
                    dndDragoverClass="!bg-primary-600/10"
                    (dndDrop)="onDropBlock($event, layerIndex, blockIndex)"
                  >
                    <i class="{{ SoctripIcons.DOWNLOAD_02 }}  text-xl"></i>
                    <div class="text-sm">Drop block here</div>
                  </div>

                  <!-- BLOCK -->
                  <ng-template #blockTemplate>
                    <div
                      class="border"
                      [ngClass]="[
                        layerIndex === elementSelected.layerIndex &&
                        blockIndex === elementSelected.blockIndex &&
                        elementSelected.scope === 'BLOCK'
                          ? 'border-gray-600'
                          : 'border-transparent'
                      ]"
                      (click)="onClickBlock($event, layerIndex, blockIndex)"
                      [ngSwitch]="block.type"
                    >
                      <!-- TEXT -->
                      <div
                        *ngSwitchCase="BLOCK_TYPE.TEXT"
                        [style]="{ 'word-break': 'break-word' }"
                      >
                        <p-editor
                          [(ngModel)]="block.data.text"
                          [style]="{ height: '450px' }"
                        >
                          <ng-template pTemplate="header">
                            <app-editor-toolbar></app-editor-toolbar>
                          </ng-template>
                        </p-editor>
                      </div>

                      <!-- IMAGE -->
                      <ng-container *ngSwitchCase="BLOCK_TYPE.IMAGE">
                        <div
                          class="bg-gray-100 min-h-[100px] text-gray-500 flex flex-col items-center justify-center gap-1"
                          *ngIf="!block.data?.desktopImg"
                        >
                          <i class="{{ SoctripIcons.IMAGE_03 }} text-xl"></i>
                          <span class="text-sm">Image</span>
                        </div>
                        <app-custom-image
                          *ngIf="block.data?.desktopImg"
                          styleClass="min-h-[200px] flex items-center"
                          imageClass="w-[unset]"
                          [imgId]="block.data?.desktopImg?.id"
                        ></app-custom-image>
                      </ng-container>

                      <!-- SLIDE -->
                      <div *ngSwitchCase="BLOCK_TYPE.SLIDE">
                        <p-galleria
                          *ngIf="
                            block.data?.banners?.length > 0;
                            else placeholderSlide
                          "
                          [value]="block.data.banners"
                          [showItemNavigators]="true"
                          [showThumbnails]="false"
                          [showIndicators]="true"
                          [indicatorsPosition]="'bottom'"
                        >
                          <ng-template pTemplate="itempreviousicon">
                            <div
                              class="bg-black/40 h-11 w-11 flex items-center justify-center rounded-full cursor-pointer hover:bg-black/60"
                            >
                              <i
                                class="{{
                                  SoctripIcons.CHEVRON_LEFT
                                }} text-white text-2xl"
                              ></i>
                            </div>
                          </ng-template>
                          <ng-template pTemplate="itemnexticon">
                            <div
                              class="bg-black/40 h-11 w-11 flex items-center justify-center rounded-full cursor-pointer hover:bg-black/60"
                            >
                              <i
                                class="{{
                                  SoctripIcons.CHEVRON_RIGHT
                                }} text-white text-2xl"
                              ></i>
                            </div>
                          </ng-template>
                          <ng-template pTemplate="item" let-item>
                            <app-custom-image
                              *ngIf="item.desktopImg?.id; else placeholderSlide"
                              class="w-full h-[240px] object-cover"
                              [imgId]="item.desktopImg?.id"
                            />
                          </ng-template>
                        </p-galleria>

                        <ng-template #placeholderSlide>
                          <div
                            class="w-full h-[240px] bg-gray-100 flex flex-col gap-1 items-center justify-center"
                          >
                            <i
                              class="{{ SoctripIcons.COLUMNS_02 }} text-xl"
                            ></i>
                            <span class="text-gray-500 text-sm">Slide</span>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <!-- DROP LAYOUT -->
            <ng-template #dropLayerTemplate>
              <div
                dndDropzone
                dndDragoverClass="!bg-gray-100"
                (dndDrop)="onDropLayout($event, layerIndex)"
              >
                <div
                  class="flex flex-col gap-1 border border-transparent items-center justify-center py-6"
                >
                  <i class="{{ SoctripIcons.DOWNLOAD_02 }}  text-xl"></i>
                  <div class="text-sm">Drop layout here</div>
                </div>
              </div>
            </ng-template>

            <!-- ABSOLUTE ITEM OF LAYER -->
            <div
              [ngClass]="
                elementSelected.scope === 'LAYER' &&
                elementSelected.layerIndex === layerIndex
                  ? 'block'
                  : 'hidden'
              "
            >
              <!-- LAYER NAME -->
              <span
                class="absolute -translate-y-full -top-2 left-2 px-2 py-[2px] text-white text-xs rounded-full bg-gray-700"
              >
                {{ layer.name || "Layer" }}
              </span>

              <!-- ADD LAYER -->
              <div
                (click)="onAddLayer($event, layerIndex)"
                class="absolute z-10 left-2 -bottom-2 translate-y-full w-9 h-9 flex items-center justify-center rounded-lg bg-gray-700 cursor-pointer hover:bg-gray-900"
              >
                <i class="{{ SoctripIcons.PLUS }} text-xl text-white"></i>
              </div>

              <!-- LAYER ACTIONS  -->
              <div
                class="absolute z-10 translate-x-full -right-1 top-0 flex flex-col gap-1"
              >
                <div
                  cdkDragHandle
                  class="w-9 h-9 flex items-center justify-center rounded-lg bg-gray-700 cursor-pointer hover:bg-gray-900"
                >
                  <i class="{{ SoctripIcons.MENU_05 }} text-xl text-white"></i>
                </div>

                <div
                  class="w-9 h-9 flex items-center justify-center rounded-lg bg-gray-700 cursor-pointer hover:bg-gray-900"
                  (click)="onCloneLayer(layerIndex)"
                >
                  <i class="{{ SoctripIcons.COPY_07 }} text-xl text-white"></i>
                </div>

                <div
                  class="w-9 h-9 flex items-center justify-center rounded-lg bg-gray-700 cursor-pointer hover:bg-gray-900"
                  (click)="onDeleteLayer(layerIndex)"
                >
                  <i class="{{ SoctripIcons.TRASH_03 }} text-xl text-white"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- RIGHT -->
    <app-right-bar-builder
      [elementSelected]="elementSelected"
      [(layers)]="layers"
    ></app-right-bar-builder>
  </div>
</div>

<ng-template #previewTemplate>
  <app-landing-page-preview
    [data]="layers"
    [previewDevice]="previewDevice"
  ></app-landing-page-preview>
</ng-template>
