import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { PREVIEW_DEVICE } from 'src/app/core/enum/marketing-builder';

@Component({
  selector: 'app-header-builder',
  templateUrl: './header-builder.component.html',
  styleUrls: ['./header-builder.component.scss'],
})
export class HeaderBuilderComponent {
  @Input() mode: 'BUILDER' | 'PREVIEW' = 'BUILDER';
  @Input() previewDevice: PREVIEW_DEVICE = PREVIEW_DEVICE.DESKTOP;

  @Output() modeChange = new EventEmitter<string>();
  @Output() previewDeviceChange = new EventEmitter<PREVIEW_DEVICE>();

  marketingName = 'Super Sale 9/9';
  languages = ['Vietnam'];

  readonly SoctripIcons = SoctripIcons;
  readonly DEVICES = [
    {
      title: 'Desktop',
      value: 'DESKTOP',
      icon: SoctripIcons.MONITOR_03,
    },
    {
      title: 'Mobile',
      value: 'MOBILE',
      icon: SoctripIcons.PHONE_02,
    },
  ];

  get isBuilder() {
    return this.mode === 'BUILDER';
  }

  get isPreview() {
    return this.mode === 'PREVIEW';
  }

  constructor(private router: Router) {}

  onChangeDevice(event: PREVIEW_DEVICE) {
    this.previewDevice = event;
    this.previewDeviceChange.emit(this.previewDevice);
  }

  onPreview() {
    this.mode = 'PREVIEW';
    this.modeChange.emit(this.mode);
  }

  onBack() {
    if (this.mode === 'BUILDER') {
      this.router.navigate(['landing-page']);
    } else {
      this.mode = 'BUILDER';
      this.modeChange.emit(this.mode);
    }
  }
}
