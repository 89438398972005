import { Component, Input } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { PREVIEW_DEVICE } from 'src/app/core/enum/marketing-builder';

@Component({
  selector: 'app-soctrip-header',
  templateUrl: './soctrip-header.component.html',
  styleUrls: ['./soctrip-header.component.scss'],
})
export class SoctripHeaderComponent {
  @Input() previewDevice: PREVIEW_DEVICE = PREVIEW_DEVICE.DESKTOP;

  readonly SoctripIcons = SoctripIcons;

  get isDesktop() {
    return this.previewDevice === PREVIEW_DEVICE.DESKTOP;
  }

  get isMobile() {
    return this.previewDevice === PREVIEW_DEVICE.MOBILE;
  }
}
