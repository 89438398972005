import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { DndDropEvent } from 'ngx-drag-drop';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  BLOCK_TYPE,
  PREVIEW_DEVICE,
} from 'src/app/core/enum/marketing-builder';
import {
  BlockData,
  ElementSelected,
  Layer,
} from 'src/app/core/models/interfaces/marketing-builder';

@Component({
  selector: 'app-landing-page-builder',
  templateUrl: './landing-page-builder.component.html',
  styleUrls: ['./landing-page-builder.component.scss'],
})
export class LandingPageBuilderComponent {
  readonly SoctripIcons = SoctripIcons;
  readonly BLOCK_TYPE = BLOCK_TYPE;

  previewDevice: PREVIEW_DEVICE = PREVIEW_DEVICE.DESKTOP;
  mode: 'PREVIEW' | 'BUILDER' = 'BUILDER';

  elementSelected: ElementSelected = {
    scope: 'LAYER',
    layerIndex: 0,
    blockIndex: 0,
  };

  layers: Layer[] = [
    {
      textColor: '#101828',
      bgColor: '#FFFFFF',
      name: '',
      description: '',
      blocks: [],
    },
  ];

  onDropBlock(event: DndDropEvent, layerIndex: number, blockIndex: number) {
    const data = event.data;
    if (data.tab !== 'BLOCK') return;

    const type = data.data.value;
    let blockData: BlockData = undefined;

    switch (type) {
      case BLOCK_TYPE.IMAGE: {
        blockData = {
          desktopImg: undefined,
          mobileImg: undefined,
          alignment: 'LEFT',
          link: '',
          isOpenNewTab: false,
        };
        break;
      }
      case BLOCK_TYPE.TEXT: {
        blockData = {
          text: '',
          description: '',
        };
        break;
      }
      case BLOCK_TYPE.SLIDE: {
        blockData = {
          banners: [
            {
              isLoading: false,
            },
          ],
        };
        break;
      }
      default: {
        return;
      }
    }

    this.layers[layerIndex].blocks[blockIndex].type = type;
    if (blockData) {
      this.layers[layerIndex].blocks[blockIndex].data = { ...blockData };
    }

    this.layers = [...this.layers];
    console.log(this.layers);
  }

  onDropLayout(event: DndDropEvent, index: number) {
    const data = event.data;
    if (data.tab !== 'LAYOUT') return;

    const layout: number[] = data.data;
    this.layers[index].blocks = layout.map((item) => ({
      flex: item,
      type: '',
      data: undefined,
      loading: false,
    }));

    this.layers = [...this.layers];
  }

  onAddLayer(event: Event, index: number) {
    event.stopPropagation();

    this.layers.splice(index + 1, 0, {
      textColor: '#101828',
      bgColor: '#FFFFFF',
      name: '',
      description: '',
      blocks: [],
    });
    this.layers = [...this.layers];

    this.elementSelected = {
      scope: 'LAYER',
      layerIndex: index + 1,
      blockIndex: 0,
    };
  }

  onClickLayer(event: Event, index: number) {
    event.stopPropagation();
    this.elementSelected = {
      scope: 'LAYER',
      layerIndex: index,
      blockIndex: 0,
    };
  }

  onClickBlock(event: Event, layerIndex: number, blockIndex: number) {
    event.stopPropagation();
    this.elementSelected = {
      scope: 'BLOCK',
      layerIndex,
      blockIndex,
    };
  }

  onDropLayer(event: CdkDragDrop<Layer[]>) {
    moveItemInArray(this.layers, event.previousIndex, event.currentIndex);
    this.layers = [...this.layers];

    this.elementSelected = {
      ...this.elementSelected,
      layerIndex: event.currentIndex,
    };
  }

  onDeleteLayer(index: number) {
    if (this.layers.length === 1) {
      this.layers = [
        {
          textColor: '#101828',
          bgColor: '#FFFFFF',
          name: '',
          description: '',
          blocks: [],
        },
      ];

      this.elementSelected = {
        scope: 'LAYER',
        layerIndex: 0,
        blockIndex: 0,
      };
    } else {
      this.layers.splice(index, 1);

      const newLayerIndex = Math.max(0, index - 1);
      this.elementSelected = {
        scope: 'LAYER',
        layerIndex: newLayerIndex,
        blockIndex: 0,
      };
    }

    this.layers = [...this.layers];
  }

  onCloneLayer(index: number) {
    const newLayer = JSON.parse(JSON.stringify(this.layers[index]));
    this.layers.splice(index + 1, 0, newLayer);

    this.layers = [...this.layers];
  }
}
