<div class="p-4 h-[calc(100vh-60px)] sticky top-[60px]">
  <div
    class="h-full bg-white min-h-full border shadow-md py-4 rounded-xl w-[360px] overflow-y-auto"
  >
    <h2 class="px-5 pb-4 font-semibold border-b">{{ getTitleRightBar() }}</h2>

    <div [ngSwitch]="elementSelected.scope">
      <!-- LAYER -->
      <div *ngSwitchCase="'LAYER'" class="px-5 pb-5 border-b">
        <h3 class="py-4 font-semibold text-sm">General Information</h3>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-1">
            <label class="font-medium text-sm">Layer name</label>
            <input
              class="h-9"
              pInputText
              [(ngModel)]="layerData.name"
              placeholder="Enter layer name"
              (input)="onUpdateLayers()"
            />
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex gap-1 items-center text-sm">
              <span class="font-medium">Description</span>
            </div>

            <p-editor
              class="rounded-lg"
              [(ngModel)]="layerData.description"
              [style]="{ height: '300px' }"
            >
              <ng-template pTemplate="header">
                <app-editor-toolbar
                  [toolbar]="['HEADING', 'TEXT_DECORATION', 'LIST']"
                ></app-editor-toolbar>
              </ng-template>
            </p-editor>
          </div>
        </div>
      </div>

      <!-- BLOCK -->
      <div *ngSwitchCase="'BLOCK'" [ngSwitch]="blockData.type">
        <!-- TEXT -->
        <div *ngSwitchCase="BLOCK_TYPE.TEXT" class="px-5 pb-5 border-b">
          <div class="py-4 font-semibold text-sm">General information</div>
          <div class="flex flex-col gap-1">
            <div class="flex gap-1 items-center text-sm">
              <span class="font-medium">Description</span>
              <span class="text-gray-500"
                >({{ blockData.description?.length || 0 }}/120)</span
              >
            </div>
            <textarea
              placeholder="Enter description"
              pInputTextarea
              cols="5"
              [(ngModel)]="blockData.description"
              [autoResize]="true"
            ></textarea>
          </div>
        </div>

        <!-- IMAGE -->
        <div *ngSwitchCase="BLOCK_TYPE.IMAGE">
          <div class="py-4 px-5 font-semibold text-sm">Upload image</div>

          <!-- Image -->
          <div class="px-5">
            <app-upload-image
              [desktopImage]="blockData.data?.desktopImg"
              [mobileImage]="blockData.data?.mobileImg"
              [isLoading]="blockData.data?.isLoading"
              (uploadImage)="onUploadImage($event)"
              (deleteImage)="onDeleteImage($event)"
            ></app-upload-image>
          </div>

          <!-- Alignment -->
          <!-- <div
            class="px-5 py-2 flex items-center justify-between border-t mt-2"
          >
            <span class="text-sm font-semibold">Alignment</span>
            <div
              class="h-9 px-[2px] flex items-center gap-[2px] rounded-lg bg-gray-100"
            >
              <span
                class="h-8 w-8 flex items-center justify-center rounded-lg cursor-pointer hover:bg-white"
                [ngClass]="
                  blockData.data?.alignment === ALIGN.value ? 'bg-white' : ''
                "
                (click)="onChangeAlignImage(ALIGN.value)"
                *ngFor="let ALIGN of IMAGE_ALIGNS"
              >
                <i class="{{ ALIGN.icon }} text-xl"></i>
              </span>
            </div>
          </div> -->

          <!-- Link -->
          <app-link-image></app-link-image>

          <!-- Effect -->
          <div class="px-5 py-2 flex items-center justify-between border-y">
            <span class="text-sm font-semibold">Effect</span>
            <div
              class="w-9 h-9 flex items-center justify-center cursor-pointer hover:bg-gray-100 rounded-lg"
            >
              <i class="{{ SoctripIcons.PLUS }} text-xl"></i>
            </div>
          </div>
        </div>

        <!-- SLIDE -->
        <div *ngSwitchCase="BLOCK_TYPE.SLIDE">
          <div class="py-4 px-5 flex flex-col gap-1">
            <span class="font-semibold text-sm">Upload banner</span>
            <p class="text-sm text-gray-500">Add up to 20 banners</p>
          </div>

          <div class="px-5 flex flex-col gap-4 pb-1">
            <div
              class="p-4 border rounded-lg bg-gray-50"
              *ngFor="let banner of blockData.data?.banners; let i = index"
            >
              <div class="mb-3 text-xs font-medium">BANNER {{ i + 1 }}</div>

              <!-- Image -->
              <app-upload-image
                [desktopImage]="banner?.desktopImg"
                [mobileImage]="banner?.mobileImg"
                [isLoading]="banner?.isLoading"
                (uploadImage)="onUploadBanner($event, i)"
                (deleteImage)="onDeleteBanner($event, i)"
              ></app-upload-image>

              <input
                pInputText
                class="h-9 rounded-lg w-full mb-4"
                placeholder="https://"
              />

              <div class="flex items-center gap-2">
                <p-checkbox [binary]="true"></p-checkbox>
                <span class="font-medium text-sm">Open in new tab</span>
              </div>
            </div>

            <app-button
              variant="outline-secondary"
              label="Add banner"
              (onClick)="onAddBanner()"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
