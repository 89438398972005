<input
  class="hidden"
  #uploadImageInput
  type="file"
  (change)="onSelectImage($event)"
/>

<div class="flex flex-col gap-4">
  <div class="rounded-lg h-9 flex items-center p-[2px] bg-gray-100">
    <div
      *ngFor="let DEVICE of DEVICES"
      (click)="device = DEVICE.value"
      [ngClass]="
        device === DEVICE.value
          ? 'bg-white font-semibold'
          : 'font-medium text-gray-500'
      "
      class="flex-1 h-8 flex items-center justify-center rounded-md text-sm cursor-pointer"
    >
      {{ DEVICE.name }}
    </div>
  </div>

  <div
    class="flex items-center gap-3 pb-5"
    *ngIf="image; else uploadImageTemplate"
  >
    <div class="border rounded-lg">
      <div class="h-24 w-24 flex items-center justify-center">
        <app-custom-image
          imageClass="h-24"
          [imgId]="image?.id"
        ></app-custom-image>
      </div>
    </div>

    <div class="h-24 flex flex-col justify-between">
      <div class="flex flex-col gap-[2px] text-sm">
        <span class="font-medium">{{ image?.original_name || "--" }}</span>
        <span class="text-gray-500"></span>
        <span class="text-gray-500">{{ image?.size || "--" }} kB</span>
      </div>
      <div class="flex items-center gap-2">
        <app-button
          variant="outline-secondary"
          label="Replace"
          styleClass="!px-3"
          (onClick)="uploadImageInput.click($event)"
        ></app-button>
        <app-button
          variant="outline-secondary"
          label="Delete"
          (onClick)="deleteImage.emit(device)"
        ></app-button>
      </div>
    </div>
  </div>
</div>

<ng-template #uploadImageTemplate>
  <div class="relative flex flex-col gap-2 mb-3">
    <p-progressSpinner
      *ngIf="isLoading"
      class="absolute -inset-2 bg-gray-300/50 flex items-center justify-center rounded-lg"
      styleClass="!w-16 !h-16"
    ></p-progressSpinner>

    <div
      class="flex flex-col items-center gap-3 py-4 px-6 rounded-lg border border-dashed bg-white"
    >
      <div
        class="w-8 h-8 flex items-center justify-center bg-blue-100 rounded-full"
      >
        <i class="{{ SoctripIcons.UPLOAD_CLOUD_01 }} text-lg text-blue-600"></i>
      </div>

      <div class="flex flex-col gap-1 items-center">
        <div class="text-sm flex items-center gap-1">
          <span
            class="text-primary-600 cursor-pointer hover:underline"
            (click)="uploadImageInput.click()"
            >Click to upload</span
          >
          <span class="text-gray-500">or drag and drop</span>
        </div>

        <span class="text-gray-500 text-sm"> Accepts PNG orJPG (Max 3MB)</span>
      </div>
    </div>
    <span class="text-xs text-gray-500">
      Recommended image size: 1920×1280px
    </span>
  </div>
</ng-template>
