<header
  class="fixed z-50 top-0 left-0 right-0 flex justify-between items-center px-6 py-3 bg-white border-b"
>
  <div class="flex items-center gap-3">
    <app-button
      styleClass="w-9"
      variant="outline-secondary"
      [icon]="SoctripIcons.CHEVRON_LEFT + ' text-xl'"
      (onClick)="onBack()"
    ></app-button>

    <input
      *ngIf="isBuilder; else previewMarketingLinkTemplate"
      pInputText
      [value]="marketingName"
      class="h-9 w-[300px]"
    />

    <ng-template #previewMarketingLinkTemplate>
      <div
        class="relative h-9 w-[400px] rounded-lg border border-gray-300 py-2 px-3"
      >
        <div class="text-sm">
          https://shop.soctrip.com/shopping/super-sale-9-9
        </div>
        <i
          class="{{
            SoctripIcons.COPY_03
          }} text-xl absolute right-3 top-1/2 -translate-y-1/2 text-gray-500"
        ></i>
      </div>
    </ng-template>

    <p-dropdown
      *ngIf="isBuilder"
      appendTo="body"
      styleClass="h-9 w-[200px] flex items-center"
      [options]="languages"
    ></p-dropdown>
  </div>

  <div
    class="flex items-center gap-[2px] h-9 p-[2px] rounded-lg bg-gray-100"
    *ngIf="isPreview"
  >
    <div
      *ngFor="let DEVICE of DEVICES"
      class="px-3 flex h-8 items-center gap-2 rounded-lg cursor-pointer"
      (click)="onChangeDevice(DEVICE.value)"
      [ngClass]="
        DEVICE.value === previewDevice
          ? 'bg-white text-blue-600'
          : 'hover:text-blue-600'
      "
    >
      <i class="{{ DEVICE.icon }}"></i>
      <span class="font-semibold text-sm">{{ DEVICE.title }}</span>
    </div>
  </div>

  <div class="flex items-center gap-3">
    <ng-container *ngIf="mode === 'BUILDER'">
      <span class="text-sm">Updated at 19/09/24 2:21 PM</span>
      <app-button
        variant="outline-secondary"
        label="Preview"
        (onClick)="onPreview()"
      ></app-button>
    </ng-container>

    <app-button variant="outline-secondary" label="Save as draft"></app-button>
    <app-button label="Save & Publish"></app-button>
  </div>
</header>
