import { Component } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';

@Component({
  selector: 'app-link-image',
  templateUrl: './link-image.component.html',
  styleUrls: ['./link-image.component.scss'],
})
export class LinkImageComponent {
  readonly SoctripIcons = SoctripIcons;
  isExpand: boolean = false;

  onToggleExpand() {
    this.isExpand = !this.isExpand;
  }
}
